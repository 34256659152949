.wrapper {
  display: grid;
  gap: 8px;
  word-break: break-all;
}

.textarea {
  border: 1px solid #ececec;
  padding: 4px;
  resize: none;
  width: 100%;
}
